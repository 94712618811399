import { FlowBase, identificationTypes } from "./flow-base";
import Utilities from "../utilities";
import * as events from "../events";

class SFCKE extends FlowBase {
    ajaxIdentify() {
        if (this.metadata["page_attributes-no-identify-sfc"]) {
            this.identifyOnSuccess(this.fakeResponse())
            return
        }
        if (!this.internalCall){
            this.integrator.customHeIdentifyGetRequestWithAuth({
                return: window.location.origin + window.location.pathname,
            }, "https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn", this.heAuthToken, this.identifyOnSuccess.bind(this), this.identifyOnError.bind(this));
        }
    }

    identifyOnSuccess(response) { // overridable
        if (this.metadata["page_attributes-debug"]) {
            var elemDiv = document.createElement('div');
            elemDiv.innerHTML = '<p style="color: white">' + JSON.stringify(response) + '</p>'
            document.body.appendChild(elemDiv);
        }

        this.identifyCallFinished = true;
        if(response.ServiceResponse.ResponseBody.Response.Msisdn){
            this.identified = true
            this.identity = response.ServiceResponse.ResponseBody.Response.Msisdn
            this.tracker.track(events.IDENTIFY_SUCCESS);
            this.showFirstStep()
        }else{
            this.tracker.track(events.IDENTIFY_FAILURE);
            this.identifyBackup()
        }
    }

    identifyOnError(response) { // overridable
        if (this.metadata["page_attributes-debug"]) {
            var elemDiv = document.createElement('div');
            elemDiv.innerHTML = '<p style="color: white">' + JSON.stringify(response) + '</p>'
            document.body.appendChild(elemDiv);
        }

        this.identifyCallFinished = true;
        this.tracker.track(events.IDENTIFY_FAILURE);
        this.identifyBackup()
    }

    sub() {
        this.logger.info('[Subscribe User] user is identified; attempting subscription', {
            identity: this.identity,
            metadata: this.metadata
        });

        var plan = this.plan;
        const planMap = this.metadata["page_attributes-plan-map"]
        if (planMap) {
            const radioInputValue = this.uiControl.getCheckedRadioInputValue("planOption")
            if(!radioInputValue){
                //TODO: show error message if user does not select a radio button
                return;
            }
            var plan = planMap[radioInputValue]
        }
        const data = {
            msisdn: this.identity,
            fraud_transaction_id: this.metadata.fraud_transaction_id,
            metadata: this.metadata,
            plan_id: parseInt(plan),
            locale: this.locale,
            tracking_id: this.metadata.event_tracking_id,
            marketing_data: this.metadata,
            subscription_data: this.metadata,
            token: this.metadata.fraud_transaction_id,
        };

        this.integrator.LPSubscribeRequest(data, this.subscribeEndpoint, this.subscribeOnSuccess.bind(this), this.subscribeOnError.bind(this))
    }

    subscribeUser() {
        if (this.identified) {
            this.setFlowStep(this.flowSteps.SPINNER)
            Utilities.waitForConditionAndExecute(
                this.isThirdPartyCallComplete.bind(this),
                this.sub.bind(this)
            );
        } else {
            if (this.identification == identificationTypes.DEBUG) {
                this.otpValidateSuccess({"status": "SUCCESS"})
                return
            }
            this.identifyBackup()
        }
    }

    subscribeOnSuccess(response) {
        if (response.status.toLowerCase() !== "subscribed") {
            // this shouldn't really occur because we got a 200
            this.subscribeOnError(response)
            return
        }

        // success condition
        if (response.new) {
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_SUCCEEDED, {
                jwt: response.access_token,
                reference: response.reference,
            });
        } else {
            this.tracker.track(events.SUBSCRIPTION_EXISTS, {
                data: {
                    reference: response.reference,
                },
            });
            this.redirectToProduct(response.access_token);
        }
    }

    subscribeOnError(response) {
    if (response.reason && response.reason === "insufficient_balance") {
        this.logger.debug(`[Subscribe Error] Flow (${this.name}): insufficent funds`, response);
        this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
        this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
            reference: response.reference,
            status_code: response.status_code,
            error: response.message || null,
            response_string: response.response_string || null,
            content_blocked: this.userContentBlocked
        });
        this.uiControl.displayCustomMessage('You have insufficient funds. Kindly top-up to enjoy the service.');
    } else {
        this.logger.error(`[Subscribe Error] Flow (${this.name}): received error from integrator`, response);
        this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
        this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
            reference: response.reference,
            status_code: response.code,
            error: response.reason,
            response_string: response.response_string || null,
        });
        this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
    }
}

    fakeResponse() {
        return {
            "ServiceResponse" : {
                "ResponseBody" : {
                    "Response" : {
                        "Msisdn" : "123456789"
                    }
                }
            }
        }
    }

    overridableInit() {
        this.thirdPartyCallComplete = false;
        window.setTimeout(this.setThirdPartyCallComplete.bind(this), 20000);
        document.addEventListener('EvinaMsisdnSaved', (event) => {
            this.thirdPartyCallComplete = true;
            this.logger.info(`[SFC Flow] - ${this.metadata.event_tracking_id} - EvinaMsisdnSaved event`);
        }, false);
    }

    setThirdPartyCallComplete() {
        this.thirdPartyCallComplete = true;
    }

    isThirdPartyCallComplete() {
        return this.thirdPartyCallComplete;
    }
}

const SFCKEFlow = new SFCKE('SFC KE', identificationTypes.AJAX, {
    subscribeClick: true,
    identifyTimeout: 20,
    exitHandlerEnabled: true,
    subscribeEndpoint: 'subscribe/token'
})
window.FlowModule = SFCKEFlow;
